import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { graphql } from 'gatsby';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { NewsCircle, NewsRectangle } from '../../assets';
import { Footer, Layout } from '../../shared/ui';
import './news-detail.css';
import { Slider } from '../../shared/ui/Slider';

const NewsDetail = (props) => {
  const data = props?.data?.contentfulNewsList;
  // console.log('NewsDetail', props);
  return (
    <Layout>
      <div className=" md:mx-8 mx-4 md:mb-[60px] mb-4">
        {data?.file && (
          <GatsbyImage
            alt={data?.file?.title}
            image={data?.file?.gatsbyImageData}
            className="hero-img w-full bg-cover bg-top object-cover h-[70vh]"
          />
        )}
      </div>
      <div className="xl:mx-[265px] lg:mx-[200px] md:mx-[100px] mx-4  font-jakarta">
        <h1 className=" text-[#fff] md:text-[32px] text-2xl font-semibold leading-normal">
          {data?.title}
        </h1>
        <div className=" p max-md:text-[14px]  flex items-baseline mt-2 gap-6">
          <div className="flex items-baseline gap-6 uppercase font-light max-md:text-xs">
            <span className="flex items-center gap-[6px]">
              <NewsCircle />
              {data?.createdAt}
            </span>
            <span className="flex items-center gap-[6px]">
              <NewsRectangle />
              {data?.type}
            </span>
          </div>
        </div>
        {data?.description && (
          <div className="my-12">{renderRichText(data?.description)}</div>
        )}

        {data?.detailSubImgs?.[0] && (
          <GatsbyImage
            alt={data?.detailSubImgs[0]?.title}
            image={data?.detailSubImgs[0]?.gatsbyImageData}
            className="w-full bg-cover h-auto object-cover mb-12"
          />
        )}

        {data?.section1 && (
          <div className="mb-12">{renderRichText(data?.section1)}</div>
        )}

        <div className="grid grid-cols-12 md:gap-7 gap-4">
          {data?.detailSubImgs?.[1] && (
            <div className=" col-span-6">
              <GatsbyImage
                alt={data?.detailSubImgs[1]?.title}
                image={data?.detailSubImgs[1]?.gatsbyImageData}
                className="w-full bg-cover h-auto object-cover "
              />
            </div>
          )}

          {data?.detailSubImgs?.[2] && (
            <div className=" col-span-6">
              <GatsbyImage
                alt={data?.detailSubImgs[2]?.title}
                image={data?.detailSubImgs[2]?.gatsbyImageData}
                className="w-full bg-cover h-auto object-cover "
              />
            </div>
          )}
        </div>
        {data?.subImgCaption && (
          <div className=" p max-md:text-[14px] w-full m-auto  italic leading-6 overflow-hidden">
            {data?.subImgCaption}
          </div>
        )}

        <div className="md:grid md:grid-cols-12 md:gap-7 gap-4">
          {data?.detailSubImgs?.[1] && data?.subImgText1 && (
            <div className="my-12 col-span-6">
              {renderRichText(data?.subImgText1)}
            </div>
          )}
          {data?.detailSubImgs?.[2] && data?.subImgText2 && (
            <div className="my-12 col-span-6">
              {renderRichText(data?.subImgText2)}
            </div>
          )}
        </div>

        {data?.section2 && (
          <div className="mb-12 mt-12">{renderRichText(data?.section2)}</div>
        )}
      </div>

      {data?.showcaseImgs?.length > 0 && (
        <div className=" mb-12 sm:mb-[74px]">
          <Slider items={data?.showcaseImgs} slidesToShow={2} />
        </div>
      )}
      <Footer />
    </Layout>
  );
};

export const query = graphql`
  query ($id: String!) {
    contentfulNewsList(id: { eq: $id }) {
      order
      slug
      title
      type
      subImgCaption
      featured_file {
        gatsbyImageData
        contentful_id
        title
      }
      file {
        gatsbyImageData
        contentful_id
        title
      }
      detailSubImgs {
        gatsbyImageData
        contentful_id
        title
      }
      description {
        raw
      }
      subImgText1 {
        raw
      }
      subImgText2 {
        raw
      }
      section1 {
        raw
      }
      section2 {
        raw
      }
      showcaseImgs {
        gatsbyImage(width: 1600, height: 900)
        contentful_id
        title
      }
      createdAt(formatString: "MMMM DD, YYYY")
    }
  }
`;

export default NewsDetail;
